var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i-empty", {
    staticClass: "noData",
    scopedSlots: _vm._u([
      {
        key: "image",
        fn: function () {
          return [
            _c("i-icon", {
              attrs: { color: "#ccc", name: "icon-wushuju", size: 160 },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "description",
        fn: function () {
          return [
            _c("div", { staticClass: "noDataTxt" }, [
              _vm._v(_vm._s(_vm.noDataTxt)),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }