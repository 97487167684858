var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-box" },
    [
      _c("i-header", {
        attrs: {
          title: _vm.config["title"] ? _vm.config["title"].template : "",
          showBack: true,
        },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "i-list",
        {
          staticClass: "list-box",
          attrs: {
            id: "scrollCnt",
            "immediate-check": false,
            finished: _vm.finished,
            "finished-text": "没有更多了",
          },
          on: { load: _vm.onload },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _vm.config["search"] &&
          _vm.config["search"].options &&
          _vm.config["search"].options.length > 0
            ? _c(
                "div",
                {
                  staticClass: "search-box",
                  class: _vm.listScrollHeight >= 90 ? "search-sticky" : "",
                },
                [
                  _vm._l(
                    _vm.config["search"].options,
                    function (search, searchIndex) {
                      return [
                        _c("i-search", {
                          key: searchIndex,
                          style: search.style,
                          attrs: {
                            shape: "round",
                            placeholder: search.placeholder,
                            readonly: search.readonly,
                          },
                          model: {
                            value: _vm.$data.searchKey[search.vModel],
                            callback: function ($$v) {
                              _vm.$set(_vm.$data.searchKey, search.vModel, $$v)
                            },
                            expression: "$data.searchKey[search.vModel]",
                          },
                        }),
                      ]
                    }
                  ),
                  _c("div", { on: { click: _vm.toSearch } }, [_vm._v("搜索")]),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.list.length > 0,
                  expression: "list.length > 0",
                },
              ],
              staticClass: "cnt-box",
            },
            [
              _c(
                "i-checkbox-group",
                {
                  attrs: { "checked-color": "var(--themeColor)" },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "cnt-item",
                      on: {
                        click: function ($event) {
                          return _vm.toggle(item, index)
                        },
                      },
                    },
                    [
                      _c("i-checkbox", {
                        ref: "checkboxes",
                        refInFor: true,
                        staticStyle: { width: "0.3rem" },
                        attrs: { name: item.$_id || item.hcpId },
                      }),
                      _c("ListCard", {
                        attrs: {
                          showBtn: false,
                          info: item,
                          infoIndex: index,
                          template: item.template,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          !_vm.list.length && !_vm.loading
            ? _c("empty-box", {
                attrs: {
                  noDataTxt: _vm.isFirst
                    ? "请输入姓名以查询数据！"
                    : "暂无数据",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.checked.length > 0
        ? _c(
            "div",
            { staticClass: "confirm-box" },
            [
              _c(
                "i-button",
                {
                  attrs: {
                    round: "",
                    color: "var(--themeColor)",
                    block: "",
                    loading: _vm.btnLoading,
                  },
                  on: { click: _vm.toSubmit },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }