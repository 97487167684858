<template>
    <i-empty class="noData">
        <template v-slot:image>
            <i-icon color="#ccc" name="icon-wushuju" :size="160"></i-icon>
        </template>
        <template v-slot:description>
            <div class="noDataTxt">{{ noDataTxt }}</div>
        </template>
    </i-empty>
</template>

<script>
export default {
    name: "index",
    props: {
        noDataTxt: {
            type: String,
            default: () => {
                return '暂无数据'
            }
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .noData {
    color: #5b6c76;
    text-align: center;

    .noDataTxt {
        font-size: 0.14rem !important;
        margin-top: -0.2rem;
    }
}
</style>
