<template>
    <div class="select-box">
        <i-header :title="config['title'] ? config['title'].template : ''"
                  :showBack="true" @back-event="toBack"></i-header>
        <i-list  class="list-box" v-model="loading"
                id="scrollCnt"
                :immediate-check="false" :finished="finished"
                finished-text="没有更多了" @load="onload">
            <div v-if="config['search'] && config['search'].options && config['search'].options.length > 0"
                 :class="listScrollHeight >= 90 ? 'search-sticky' : ''"
                 class="search-box">
                <template v-for="(search, searchIndex) in config['search'].options">
                    <i-search :style="search.style" shape="round" v-model="$data.searchKey[search.vModel]" :placeholder="search.placeholder" :readonly="search.readonly" :key="searchIndex" />
                </template>
                <div @click="toSearch">搜索</div>
            </div>
            <div class="cnt-box" v-show="list.length > 0">
                <i-checkbox-group checked-color="var(--themeColor)" v-model="checked">
                    <div class="cnt-item" v-for="(item, index) in list" :key="index" @click="toggle(item, index)">
                        <i-checkbox style="width: 0.3rem" :name="item.$_id || item.hcpId" ref="checkboxes"></i-checkbox>
                        <ListCard :showBtn="false" :info="item" :infoIndex="index" :template="item.template"></ListCard>
                    </div>
                </i-checkbox-group>
            </div>
            <empty-box v-if="!list.length && !loading" :noDataTxt="isFirst ? '请输入姓名以查询数据！' : '暂无数据'" />
        </i-list>
        <!--        <i-skeleton v-if="isFirst" title :row="8" />-->
        <div v-if="checked.length > 0" class="confirm-box">
            <i-button round color="var(--themeColor)" block @click="toSubmit" :loading="btnLoading">保存</i-button>
        </div>
    </div>
</template>

<script>
import ListCard from "./ListCard";
import emptyBox from "@/components/iEmpty";
import generic from "../../utils/generic";

export default {
    name: "Select",
    components: {
        ListCard,
        emptyBox,
    },
    data() {
        return {
            test1: "",
            name: "",
            hospital: "",
            searchKey: {},
            pageIndex: 1,
            pageSize: 10,
            list: [],
            total: 0,
            isFirst: true,
            loading: false,
            finished: false,
            checked: [],
            btnLoading: false,
            config: {
                select: [],
            },
            subType: this.$route.query.subType,

            listScrollHeight: 0
        };
    },
    created() {
        this.getConfig();
    },
    mounted() {
        document.querySelector("#scrollCnt").addEventListener("scroll",this.scrollToTop)
    },
    beforeDestroy() {
    },
    methods: {
        // 滚动距离
        scrollToTop() {
            this.listScrollHeight = document.querySelector("#scrollCnt").scrollTop
        },
        getConfig() {
            generic
                .getConfigs(
                    {
                        miceId: this.$route.query.miceId,
                        group: this.$route.query.type,
                        router: this.$route.path,
                    },
                    Object.assign(this.$smartStorage.get("miceInfo"), { subType: this.subType })
                )
                .then((config) => {
                    console.log('2121',config);
                    this.config = config;
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]();
                    }
                    console.log('config',this.config);
                });

        },
        searchInit() {
            if (this.config["search"] && this.config["search"].options) {
                let miceInfo = this.$smartStorage.get("miceInfo") || {};
                this.config["search"].options = generic.replaceEval(this.config["search"].options, miceInfo);
                this.config["search"].options.forEach((item) => {
                    // 绑定搜索字段
                    this.$set(this.searchKey, item.vModel, item.defaultVal);
                });
            }
        },
        toSearch() {
            if (this.config["search"] && this.config["search"].checkList) {
                let context = this.$data;
                let checkList = this.config["search"].checkList;
                let checkExpr = checkList.filter((item) => {
                    return eval(item.expr);
                });
                let sign = true;
                if (checkExpr.length > 0) {
                    checkExpr.forEach((item) => {
                        this.$idialog
                            .alert({
                                title: "提示",
                                message: item.errorMsg,
                            })
                            .then(() => {});
                        sign = false;
                    });
                }
                if (!sign) return false;
            }
            this.pageIndex = 1;
            this.finished = false;
            this.$nextTick(() => {
                this.getList(true);
            }, 800);
        },
        toBack() {
            this.$router.back();
        },
        toggle(item, index) {
            item.subType = this.$route.query.subType;
            item.miceId = this.$route.query.miceId;
            this.$refs.checkboxes[index].toggle();

            let meta = generic.replaceEvalSingle([this.config["create"]], item);
            console.log("====点击项目", meta);

            if (
                this.config["create"].params.metas.find((x) => {
                    if (this.config["list"].primaryKey) {
                        return x[this.config["list"].primaryKey] == meta[this.config["list"].primaryKey];
                    } else {
                        return x.code == meta.code;
                    }
                })
            ) {
                this.config["create"].params.metas = this.config["create"].params.metas.filter((x) => {
                    if (this.config["list"].primaryKey) {
                        return x[this.config["list"].primaryKey] != meta[this.config["list"].primaryKey];
                    } else {
                        return x.code != meta.code;
                    }
                });
            } else {
                this.config["create"].params.metas.push(meta);
            }
        },
        async getList(isSearch = false) {
            let _config = generic.replaceEvalObject(this.config["list"],
                Object.assign(
                    this.$route.query,
                    this.$data,
                        { userMsg: this.$smartStorage.get("userMsg") || {} },
                    { DtStart: this.$smartStorage.get("miceInfo")?.DtStart || "",
                        TypeDictVal: this.$smartStorage.get("miceInfo")?.TypeDictVal || "" }));
            let params = _config.params;
            this.loading = true;
            let res = await this.$service.common(_config.api, params);
            if (res && res.success && res.content) {
                this.isFirst = false;
                if (isSearch) this.list = [];
                // 处理显示字段
                if (res.content.rows) {
                    res.content.rows.forEach((item) => {
                        if (this.config["listCard"]) item.template = this.config["listCard"].template;
                        // 添加唯一key,上传数据的时候也需要有个唯一key，并且和列表数据中唯一key一致
                        if (this.config["list"].primaryKey) {
                            item.$_id = item[this.config["list"].primaryKey];
                        }
                    });
                    this.list = this.list.concat(res.content.rows);
                }
                this.total = res.content.total;
            }
            this.loading = false;
        },
        onload() {
            if (this.pageSize * this.pageIndex >= this.total) {
                this.finished = true;
            } else {
                this.pageIndex += 1;
                this.getList();
            }
        },
        toSubmit() {
            if (this.checked.length == 0) {
                this.$itoast("请" + (this.config["title"]?.template || "选择参会人"));
                return false;
            }
            this.btnLoading = true;
            console.log("====发送数据", this.config.create);
            let config = this.jsonCopy(this.config.create);
            config.params.metas.shift();
            this.$service.GenericPost(config).then((res) => {
                this.btnLoading = false;
                if (res && res.success) {
                    this.$itoast("操作成功");
                    this.$router.back();
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.select-box {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    /deep/.sticky-box {
        height: 0.44rem;
        box-sizing: border-box;
        .i-header .header-bg {
            background: #FFFFFF !important;
        }
    }

    .list-box {
        //height: calc(100vh - 1.39rem);
        flex: 1;
        overflow-y: auto;
        .title-box {
            text-align: left;
            color: #13161b;
            font-size: 0.24rem;
            padding: 0.1rem 0 0.1rem 0.25rem;
            box-sizing: border-box;
        }
        .search-box {
            width: 100%;
            height: 0.34rem;
            padding: 0 0.1rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .van-search {
                padding: unset;
                background: #F5F5F5;
                border-radius: 0.04rem;
            }
        }
        .search-sticky {
            position: absolute;
            left: 0;
            top: 0.44rem;
            z-index: 100;
        }
        .cnt-box {
            padding: 0 0.1rem 0.25rem 0.1rem;
            box-sizing: border-box;
            .cnt-item {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 0.27rem;
                .item-box {
                    width: 100%;
                    //margin-left: 0.21rem;
                }
            }
        }
    }
    .confirm-box {
        //position: fixed;
        //bottom: 0;
        width: 100vw;
        height: 0.7rem;
        background: #ffffff;
        box-shadow: 0px -0.16rem 0.16rem 0px #ffffff;
        border-radius: 0.33rem 0.33rem 0px 0px;
        padding: 0.05rem 0.25rem 0 0.25rem;
        box-sizing: border-box;
        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
    }
}
</style>
